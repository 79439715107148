import React from 'react';
import { Box, Typography } from '@mui/material';
import classes from '../contact.module.scss';

const ContactMessage: React.FC = () => {
  return (
    <Box className={classes['contact-message']}>
      <Typography variant="h6" color="secondary" align="center">
        We'd love to hear from you!
      </Typography>
      <Typography variant="body1" color="secondary" align="center">
        Have a question, need assistance, or want to learn more about our auto title services? Don't hesitate to reach out to us.
      </Typography>
      <Typography variant="body1" color="secondary" align="center">
        Our team is ready to help you with your inquiries and provide the information you need.
      </Typography>
      <Typography variant="body1" color="secondary" align="center">
        Contact us today and let's get started on your auto title journey!
      </Typography>
    </Box>
  );
};

export default ContactMessage;

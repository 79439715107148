import React from 'react';
import classes from './home.module.scss';
import { Box, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { buttonGradientStyle } from '../../common/mui.style';
import atdLogo from '../../assets/img/atd-logo-home.svg';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const navigateToForm = () => {
    navigate('./services')
  }


  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      height: '100%',
    }} className={classes["home-page"]}>
      <div className={classes["parallax"]}>
        <div className={classes["centered-content"]}>
          <Grid container spacing={2} className={classes['grid-container']}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes["svg-container"]}>
                <img className={classes['svg-img-logo']} src={atdLogo} alt="Logo" />
              </div>
              <div>
                <h5 className={classes["square-animation"]}>
                  Auto Title Direct Nationwide Auto Title Solutions Empowering You With Effortless Title Resolutions
                </h5>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Button sx={buttonGradientStyle} onClick={navigateToForm}>
                Get Started
              </Button>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <img className={classes['img-logo']} src='/img/logo-sm.PNG' />
            </Grid>
          </Grid>

        </div>


      </div>
    </Box>
  );
};

export default HomePage;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createMuiTheme, createTheme, CssBaseline, ThemeProvider } from '@mui/material'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  
  palette: {
    primary: {
      main: '#000',
      light: 'rgba(195,195,195,0.11)',
    },
    secondary: {
      main: '#fff',
    },
    background: {
       default: 'linear-gradient(135deg, #000000, #7d0000e8)',
      // paper: '#000000',
    },
    text: {
      primary: '#000000',
      secondary: '#ffff',
    },
    divider: '#ffffff',
  },
  typography: {
    fontFamily: 'Nunito',
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
        <App />

    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { getFirestore, collection, query, where, getDocs, doc, setDoc, addDoc, serverTimestamp } from "firebase/firestore";
import { app } from "../firebaseConfig";
import Company from "../pages/service-form/models/company.model";
import { Confirmation } from "../pages/service-form/models/confirmation.model";
import { timeStamp } from "console";

const db = getFirestore(app);

export const handleCompanyUpsert = async (company: Company) => {

    try {
        const companyInfoRef = collection(db, 'companyInfo');
        const emailQuery = query(companyInfoRef, where('emailAddress', '==', company.emailAddress));
        const querySnapshot = await getDocs(emailQuery);

        let companyDocRef;

        if (!querySnapshot.empty) {
            // Document exists, update it
            companyDocRef = doc(db, 'companyInfo', querySnapshot.docs[0].id);

            const companyData = {
                companyName: company.companyName,
                address: company.address,
                phoneNumber: company.phoneNumber,
                emailAddress: company.emailAddress,
            };

            await setDoc(companyDocRef, companyData, { merge: true });
        } else {
            // Document doesn't exist, create a new one
            const newCompanyDocRef = await addDoc(companyInfoRef, {
                companyName: company.companyName,
                address: company.address,
                phoneNumber: company.phoneNumber,
                emailAddress: company.emailAddress,
            });

            companyDocRef = doc(db, 'companyInfo', newCompanyDocRef.id);
        }

        return companyDocRef; // Return the DocumentReference
    } catch (error) {
        console.error('Error upserting company data:', error);
    } finally {
        console.info('Done updserting company info ');
    }
};
export const submitTransactionToDb = async (company: Company, confirmation: Confirmation, paymentId: string, emailSent: boolean, confirmationId: string): Promise<string> => {
    let confirmId = confirmationId;
    try {
        confirmId = await upsertConfirmation(company, confirmation, paymentId, confirmId, emailSent);
    } catch (error) {
        console.error('Error posting data:', error);
    } finally {
        return confirmId;            // Set loading to false after the API call completes
    }
};

export const submitErrorToDb = async (error: any)  => {
    await addDoc(collection(db, 'errorHandling'), {
        response: error.body,
        statusCode: error.statusCode,
        timeStamp: serverTimestamp()
    });
    
} 

async function upsertConfirmation(company: Company, confirmation: Confirmation, paymentId: string, confirmId: string, emailSent: boolean) {
    // Step 2: Use the returned companyId as a foreign key in the confirmation collection
    let confirmationDocRef = null;
    try {
        const confirmationData = {
            companyEmailAddress: company.emailAddress,
            totalPrice: confirmation.totalPrice,
            timestamp: serverTimestamp(),
            paymentId: paymentId,
            emailSent: emailSent,
            companyId: confirmation.companyId,
            services: confirmation.services.map((service) => ({
                serviceType: service.serviceType,
                vinNumber: service.vinNumber,
                date: service.date,
                amount: service.amount,
                price: service.price,
            })),
        };

        if (confirmId) {
            // If confirmId exists, update the document with the provided confirmId
            await setDoc(doc(db, 'confirmation', confirmId), confirmationData);
            confirmationDocRef = doc(db, 'confirmation', confirmId);
        } else {
            // If confirmId doesn't exist, create a new document
            confirmationDocRef = await addDoc(collection(db, 'confirmation'), confirmationData);
        }
    } catch (error) {
        console.error('Error upserting confirmation data:', error);
    } finally {
        console.info('Data posted successfully');
        confirmId = confirmationDocRef?.id || '';
    }

    return confirmId;
}

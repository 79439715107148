import React, { useState, ChangeEvent } from 'react';
import {
  Card,
  CardContent,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Grid,
  Icon,
  Divider,
  Box,
} from '@mui/material';
import Company from '../models/company.model';
import Service from '../models/service.model';
import { Timestamp } from 'firebase/firestore';
import styles from './services-form.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import { buttonGradientStyle } from '../../../common/mui.style';
interface ServicesProps {
  services: Service[];
  onChange: (services: Service[], index: number) => void;
  onAddService: () => void;
  onDeleteService: (index: number) => void;
  validateServicesForm : (isValid: boolean) => void;
}

// Rest of the code...

const ServicesForm: React.FC<ServicesProps> = ({
  services,
  onChange,
  onAddService,
  onDeleteService,
  validateServicesForm
}) => {
  let updatedServices = [...services];
  const validateForm = () => {
    const updatedErrors: Partial<Service>[] = updatedServices.map((service) => {
      const serviceErrors: Partial<Service> = {};

      // Validate VIN Number
      const vinNumberError = getVinNumberError(service.vinNumber);
      if (vinNumberError) {
        serviceErrors.vinNumber = vinNumberError;
      }

      const dateError = getDateError(service.date);
      if(dateError) {
        serviceErrors.date = null;
      }

      return serviceErrors;
    });

    const formErrors: Partial<Service> = updatedErrors.reduce(
      (prevErrors, currentErrors) => ({
        ...prevErrors,
        ...currentErrors
      }),
      {}
    );

    return Object.keys(formErrors).length === 0; // Return true if there are no errors
  };


  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>, index: number) => {
    const { name, value } = event.target;
    let convertedDate: Timestamp | null = null;
    if (name === 'date' && value) {
      const date = new Date(value);
      convertedDate = Timestamp.fromDate(date);
    }
    const inputValue = (event.target as HTMLInputElement | HTMLTextAreaElement).value;

    updatedServices = [...services];
    updatedServices[index] = {
      ...updatedServices[index],
      [name]: inputValue,
    };
    validateServicesForm(validateForm())
    onChange(updatedServices, index);
  };

  const validateVinNumber = (vinNumber: string): boolean => {
    // Modify the regular expression as per your requirements
    const regex = /^[A-HJ-NPR-Z0-9]{17}$/;
    return regex.test(vinNumber);
  };


  const getVinNumberError = (vinNumber: string): string => {
    if (!vinNumber) {
      return 'VIN Number is required';
    }
    if (!validateVinNumber(vinNumber)) {
      return 'Invalid VIN Number';
    }
    return '';
  };

  const getAmountError = (amount: number | null): string => {
    if (!amount) {
      return 'Amount is required';
    }
    return '';
  };

  const isVinNumberValid = (vinNumber: string): boolean => {
    return getVinNumberError(vinNumber) === '';
  };

  const isAmountValid = (amount: number | null): boolean => {
    return getAmountError(amount) === '';
  };


  const getDateError = (date: Timestamp | null): string => {
    if (!date) {
      return 'Date is required';
    }

    // Add your date validation logic here
    // For example, check if the date is in the future or if it meets a specific format

    return '';
  };

  const isDateValid = (date: Timestamp | null): boolean => {
    return getDateError(date) === '';
  };


  return (
    <div className={styles['services-form-container']}>
      <form onSubmit={validateForm}>
        {services.map((service, index) => (
          <Grid sx={{ marginTop: 0 }} className={styles['service-form-grid']} container spacing={3} key={index}>
            <Grid item xs={6} sm={3}>
              <FormControl fullWidth error={!service.serviceType}>
                <InputLabel>Service Type</InputLabel>
                <Select
                  className={styles["services-form-input"]}
                  value={service.serviceType}
                  onChange={(event) => handleInputChange(event, index)}
                  inputProps={{ name: 'serviceType', 'data-index': index }}
                >
                  <MenuItem value="">Select Service Type</MenuItem>
                  <MenuItem value="Mechanics Lien">Mechanics Lien</MenuItem>
                  <MenuItem value="Abandoned Vehicle">Abandoned Vehicle</MenuItem>
                  <MenuItem value="Lost Title">Lost Title</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                className={styles["services-form-input"]}
                label="VIN Number"
                value={service.vinNumber}
                onChange={(event) => handleInputChange(event, index)}
                name="vinNumber"
                InputProps={{ style: { color: '#FFF' } }}
                fullWidth
                error={!isVinNumberValid(service.vinNumber)}
                helperText={getVinNumberError(service.vinNumber)}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
            <TextField
                className={styles["services-form-input"]}
                label="Date"
                type="date"
                value={service.date ? service.date : ''}
                onChange={(event) => handleInputChange(event, index)}
                name="date"
                fullWidth
                InputProps={{ style: { color: '#FFF' } }}
                InputLabelProps={{ shrink: true }}
                error={!isDateValid(service.date)}
                helperText={getDateError(service.date)}
              />
            </Grid>
            {service.serviceType !== 'Lost Title' && <Grid item xs={6} sm={3}>
              <TextField
                className={styles["services-form-input"]}
                label="Amount"
                type="number"
                value={service.amount || ''}
                onChange={(event) => handleInputChange(event, index)}
                name="amount"
                InputProps={{ style: { color: '#FFF' } }}
                fullWidth
                error={!isAmountValid(service.amount)}
                helperText={getAmountError(service.amount)}
              />
            </Grid>}
            <Grid className={styles['delete-button-container']} item xs={6} sm={1}>
              <Button variant="contained" color="secondary" onClick={() => onDeleteService(index)}>
                <DeleteIcon />
              </Button>
            </Grid>
            {services.length > 1 && <Grid item xs={12}>
              <Divider sx={{ backgroundColor: '#FFF' }} />
            </Grid>}
          </Grid>
        ))}

        <Button sx={buttonGradientStyle} className={styles["add-button"]} variant="contained" color="primary" onClick={onAddService}>
          Add Service
        </Button>
      </form>
    </div>
  );
};

export default ServicesForm;



// components

// import Footer from "../components/footer/footer.component";
// import Header from "../components/menu/menu.comonent";
import { Box } from '@mui/material';
import Footer from '../footer/footer.component';
import Nav from '../nav/nav.component';
// import './layout.scss'



const Layout = ({ children }: any) => {


  return (
    <>
    <Box>
    <main id="top">{children}</main>

    </Box>


    </>


  );
};

export default Layout;
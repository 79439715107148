import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';
import HomePage from './pages/home/home.component';
import { auth } from './firebaseConfig';
import PrivateRoute from './components/private-router/private-router.component';
import Layout from './components/layout/layout.component';
import Navigation from './components/nav/nav.component';
import ServiceForm from './pages/service-form/service-form.component';
import Footer from './components/footer/footer.component';
import ContactPage from './pages/contact/contact.component';

const App: React.FC = () => {
  const isAdminLoggedIn = !!auth.currentUser;

  return (
    <div className="App">
      <Layout>
        <Router>
          <Navigation />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/services" element={<ServiceForm />} />
            <Route path="/contact" element={<ContactPage/>}/>
            {/* <Route path="/login" element={<LoginPage />} /> */}
            {/* <PrivateRoute path="/admin" element={<div />} /> */}
          </Routes>
          {/* <Footer /> */}

        </Router>
      </Layout>
    </div>


  );
};

export default App;

import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import Company from '../models/company.model';
import Service from '../models/service.model';
import styles from './checkout.module.scss';
import Pricing from '../models/pricing.model';
import { app } from '../../../firebaseConfig'
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { Confirmation } from '../models/confirmation.model';
import { formatToDollar } from '../../../common/helper.utils';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from '../../../stripeConfig';
import CreditCardForm from '../../../components/credit-card-form/credit-card-form.component';

interface CheckoutPageProps {
  company: Company;
  confirmation: Confirmation;
  pricingData: Pricing;
}

const CheckoutPage: React.FC<CheckoutPageProps> = ({ company, confirmation, pricingData }) => {


  return (
    <div className={styles["checkout-page"]}>
      <Card
        style={{
          background: 'linear-gradient(31deg, rgba(12,14,21,1) 3%, rgba(28,33,47,1) 16%, rgba(55,54,59,1) 66%, rgba(59,58,62,1) 76%, rgba(93,90,89,1) 97%)',
          color: 'white',
        }}
        className={styles["form-card"]}
      >
        <CardContent>
          {/* Display the company information */}
          <h2>{company.companyName}</h2>
          <p><strong>Address:</strong> {company.address}</p>
          <p><strong>Phone Number:</strong> {company.phoneNumber}</p>
          <p><strong>Email Address:</strong> {company.emailAddress}</p>
        </CardContent>
      </Card>

      {/* Display the itemized receipt */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'white' }}>Service Type</TableCell>
              <TableCell sx={{ color: 'white' }}>VIN Number</TableCell>
              <TableCell sx={{ color: 'white' }}>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {confirmation?.services.map((service) => (
              <TableRow key={service.vinNumber}>
                <TableCell sx={{ color: 'white' }}>{service.serviceType}</TableCell>
                <TableCell sx={{ color: 'white' }}>{service.vinNumber}</TableCell>
                <TableCell sx={{ color: 'white' }}>{formatToDollar(service.price)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Display the total balance */}
      <div>
        <hr /> {/* Divider line */}
        {confirmation?.services.length >= pricingData.discountThreshold && (
          <p
            style={{
              backgroundImage: 'linear-gradient(41deg, rgba(55,32,19,1) 7%, rgba(245,234,195,1) 50%, rgba(241,233,147,1) 80%)',
              color: 'white',
              padding: '10px',
            }}
          >
            Discount applied
          </p>
        )}
        <h3 style={{ color: 'white' }}>Total Balance: {formatToDollar(confirmation.totalPrice)}</h3>
      </div>
    </div>
  );
};

export default CheckoutPage;



import axios from 'axios';
import ReceiptHeader from '../../pages/service-form/models/receipt.model';
import { FunctionUrls } from '../const/urls.constants';

// ...

export const sendEmail = async (receiptHeader: ReceiptHeader, confirmationCode: string) => {
  receiptHeader.receipt.confirmation.confirmationCode = confirmationCode;
  const msg = {
    to: receiptHeader.to,
    from: 'autotitledirect@gmail.com',
    subject: `Confirmation Receipt: ${confirmationCode}`,
    receipt: receiptHeader.receipt
  };

  const response = await fetch(FunctionUrls.SEND_EMAIL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(msg)
  });

  const responseBody = await response.json();

  return responseBody;
};

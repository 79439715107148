import React, { useEffect, useRef, useState } from 'react';
import { CardElement, useStripe, useElements, CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import styles from './credit-card-form.module.scss';
import { Alert, Button, Card, CardContent, Snackbar, TextField } from '@mui/material';
import { buttonGradientStyle } from '../../common/mui.style';
import { Confirmation } from '../../pages/service-form/models/confirmation.model';
import { submitErrorToDb, submitTransactionToDb } from '../../common/db.helper';
import Company from '../../pages/service-form/models/company.model';
import { FunctionUrls } from '../../common/const/urls.constants';
import { sendEmail } from '../../common/config/sengrid';
import ReceiptHeader, { Receipt } from '../../pages/service-form/models/receipt.model';

interface Props {
    company: Company;
    confirm: Confirmation;
    setOnLoadingStatus: (status: boolean) => void;
    setIsTransactionComplete: (status: boolean) => void;
    setConfirm: (object: any) => void;
}
const CreditCardForm: React.FC<Props> = ({ company, confirm, setOnLoadingStatus, setIsTransactionComplete, setConfirm }) => {
    const [confirmation, setConfirmation] = useState<Confirmation>(confirm);
    const stripe = useStripe();
    const elements = useElements();
    const [formValues, setFormValues] = useState({
        cardHolderName: '',
        postalCode: '',
    });

    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');

    const getEmailReceipt = (): ReceiptHeader => {
        const receipt: Receipt = {
            company: company,
            confirmation: confirmation
        };
        return {
            to: company.emailAddress,
            receipt: receipt,
        }
    }
    const createCustomer = async (paymentMethodId: string | undefined) => {
        const response = await fetch(FunctionUrls.CREATE_CUSTOMER, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: company.emailAddress,
                source: paymentMethodId
            })
        });

        const customerObject = await response.json();

        if (customerObject?.err) {
            throw new Error(JSON.stringify(customerObject));
        }

        return customerObject;
    };

    const createPaymentIntent = async (
        amount: number,
        customerId: string | undefined,
        paymentMethodId: string | undefined,
        emailAddress: string
    ) => {
        const response = await fetch(FunctionUrls.CREATE_PAYINTENT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                amount: amount * 100,
                currency: 'usd',
                customer: customerId,
                paymentMethodId: paymentMethodId,
                emailAddress: emailAddress
            })
        });

        const paymentIntentObject = await response.json();

        if (paymentIntentObject?.err) {
            throw new Error(JSON.stringify(paymentIntentObject));
        }

        return paymentIntentObject;
    };

    const handleCardPaymentIntent = async (event: React.FormEvent): Promise<string> => {
        event.preventDefault();
        if (!stripe || !elements) {
            return '';
        }

        // card number element as the card element
        const cardNumberElement = elements?.getElement(CardNumberElement);

        if (cardNumberElement) {
            try {
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardNumberElement,
                    billing_details: {
                        address: {
                            postal_code: formValues.postalCode
                        },
                        name: formValues.cardHolderName, // Replace 'John Doe' with the actual name of the user
                        email: company.emailAddress,
                        phone: company.phoneNumber
                    },
                });
                if (error) {
                    return handleError(error);

                }
                setOnLoadingStatus(true); // Set loading to true before making the API call

                setOnLoadingStatus(true);

                const customerObject = await createCustomer(paymentMethod?.id);

                const paymentIntentObject = await createPaymentIntent(
                    confirm.totalPrice,
                    customerObject?.customer?.id,
                    paymentMethod?.id,
                    company.emailAddress
                );

                if (paymentIntentObject && paymentIntentObject?.payIntent) {
                    const confirmationId = await submitTransactionToDb(
                        company,
                        confirmation,
                        paymentIntentObject?.payIntent.id,
                        false,
                        ''
                    );

                    setIsTransactionComplete(true);
                    setConfirm({ ...confirmation, paymentId: paymentIntentObject?.payIntent.id, confirmationCode: confirmationId });

                    const emailResponse : any = await sendEmail(getEmailReceipt(), confirmationId);

                    if (emailResponse?.message === 'Receipt email sent successfully') {
                        await submitTransactionToDb(company, confirmation, paymentIntentObject?.payIntent.id, true, confirmationId);
                        console.info('Email sent');
                    } else {
                        await submitErrorToDb(emailResponse);
                    }
                } else {
                    throw new Error('Error occurred processing your payment');
                }

                setOnLoadingStatus(false);
            }
            catch (error) {
                return handleError(error);
            }
        }


        return '';


        function handleError(error: any) {
            setError(error?.message || error?.err.raw?.message || 'An error occurred.');
            setOnLoadingStatus(false);
            setOpen(true);
            submitErrorToDb({body: error?.message || error?.err  || 'An error occurred.', statusCode: error?.err?.statusCode || ''});
            console.error('Error confirming payment:', error);
            return '';
        }
    };

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: value,
        }));
    };
    const elementOptions = {
        style: {
            base: {
                color: 'white',
            },
        },
        // other options
    };

    return (
        <Card
            style={{
                background: 'linear-gradient(31deg, rgba(12,14,21,1) 3%, rgba(28,33,47,1) 16%, rgba(55,54,59,1) 66%, rgba(59,58,62,1) 76%, rgba(93,90,89,1) 97%)',
                color: 'white',
            }}
            className={styles["form-card"]}
        >
            <CardContent>
                <h2>Payment Information</h2>
                {/* Display the company information */}
                <div className={styles["credit-card-form"]}>
                    <form>
                        <div className={styles["group"]}>
                            <div className={styles["credit-card"]}>
                                <div className={styles["card-info"]}>
                                    <label>
                                        <span>Card Holder Name</span>
                                        <input
                                            id="postal-code"
                                            defaultValue={formValues.cardHolderName}
                                            name="cardHolderName"
                                            className={styles["field"]}
                                            placeholder="Card Holder"
                                            onChange={handleInputChange}
                                        />
                                    </label>
                                    <label>
                                        <span>Card number</span>
                                        <CardNumberElement id="card-number-element" className={styles["field"]} options={elementOptions} />
                                    </label>
                                    <label>
                                        <span>Expiry date</span>
                                        <CardExpiryElement id="card-expiry-element" className={styles["field"]} options={elementOptions} />
                                    </label>
                                    <label>
                                        <span>CVC</span>
                                        <CardCvcElement id="card-cvc-element" className={styles["field"]} options={elementOptions} />
                                    </label>

                                    <label>
                                        <span>Postal code</span>
                                        <input
                                            id="postal-code"
                                            defaultValue={formValues.postalCode}
                                            name="postalCode"
                                            className={styles["field"]}
                                            placeholder="Postal Code"
                                            onChange={handleInputChange}
                                        />
                                    </label>
                                </div>

                            </div>
                        </div>
                        <Button sx={buttonGradientStyle} className={styles["action-button"]} variant="contained" color="primary" onClick={handleCardPaymentIntent}>
                            Submit Payment
                        </Button>
                    </form>
                </div>
            </CardContent>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                style={{
                    marginBottom: '150px' // Adjust this value to position the Snackbar within the visibility of the screen
                }}
            >
                <Alert onClose={() => setOpen(false)} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </Card>



    );
};

export default CreditCardForm;

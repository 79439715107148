import { app } from "../../firebaseConfig";

const projectId = app.options.projectId
export const FunctionUrls = {
  BASE_URL: 'https://rynyyrgq1e.execute-api.us-east-2.amazonaws.com',
  CREATE_CUSTOMER: `https://rynyyrgq1e.execute-api.us-east-2.amazonaws.com/Stage/create-customer`,
  CREATE_PAYINTENT: 'https://rynyyrgq1e.execute-api.us-east-2.amazonaws.com/Stage/create-payintent',
  SEND_EMAIL: 'https://rynyyrgq1e.execute-api.us-east-2.amazonaws.com/Stage/send-email-node'
};

if (projectId === 'auto-title-direct') {
  // Development environment
} else if (projectId === 'auto-title-direct-prod-82a69') {
  FunctionUrls.CREATE_CUSTOMER = 'https://rynyyrgq1e.execute-api.us-east-2.amazonaws.com/prod/create-customer';
  FunctionUrls.CREATE_PAYINTENT = 'https://rynyyrgq1e.execute-api.us-east-2.amazonaws.com/prod/create-payintent'
  FunctionUrls.SEND_EMAIL = 'https://rynyyrgq1e.execute-api.us-east-2.amazonaws.com/prod/send-email-node'
  // Production environment
} else {
  // Other environment or unrecognized project ID
  console.log('Running in an unrecognized environment');
}


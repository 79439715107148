import React, { useState } from 'react';
import { TextField, Card, CardMedia } from '@material-ui/core';
import Company from '../models/company.model';
import styles from './company-info.module.scss';
import atdLogo from '../../../assets/img/atd-logo.svg';

interface CompanyInfoProps {
  company: Company;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors: Partial<Company>
}

const CompanyInfoForm: React.FC<CompanyInfoProps> = ({ company, onChange, errors }) => {

  return (
    <div className={styles['company-info']}>
      <Card className={styles['form-card']}>
        <CardMedia
          className={styles['form-card-media']}
          component='img'
          src={atdLogo}
          title="Form Background"
          style={{
            background:
              'linear-gradient(31deg, rgba(12,14,21,1) 3%, rgba(28,33,47,1) 16%, rgba(55,54,59,1) 66%, rgba(59,58,62,1) 76%, rgba(93,90,89,1) 97%)',
            height: '300px',
          }}
        />
        <div className={styles['form-container']}>
          <form>
            <div className={styles['form-row']}>
              <div className={styles['form-column']}>
                <TextField
                  className={styles['form-control']}
                  label="Company Name"
                  name="companyName"
                  value={company.companyName}
                  onChange={onChange}
                  fullWidth
                  error={!!errors.companyName}
                  helperText={errors.companyName}
                />
                <TextField
                  className={styles['form-control']}
                  label="Address"
                  name="address"
                  value={company.address}
                  onChange={onChange}
                  fullWidth
                  error={!!errors.address}
                  helperText={errors.address}
                />
              </div>
              <div className={styles['form-column']}>
                <TextField
                  className={styles['form-control']}
                  label="Email Address"
                  name="emailAddress"
                  value={company.emailAddress}
                  onChange={onChange}
                  fullWidth
                  error={!!errors.emailAddress}
                  helperText={errors.emailAddress}
                />
                <TextField
                  className={styles['form-control']}
                  label="Phone Number"
                  name="phoneNumber"
                  value={company.phoneNumber}
                  onChange={onChange}
                  fullWidth
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber}
                />
              </div>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default CompanyInfoForm;

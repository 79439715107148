export const buttonGradientStyle = {
    background: 'linear-gradient(41deg, rgba(55,32,19,1) 7%, rgba(245,234,195,1) 50%, rgba(241,233,147,1) 80%)',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    borderRadius: 2,
    border: 0,
    color: 'white',
    padding: '0 20px',
    fontWeight: 'bold',
    fontSize: '1rem',
};

export const backButtonGradientStyle = {
    background: 'black',
    boxShadow: '0 3px 5px 2px rgba(255, 255, 255, .3)',
    borderRadius: 2,
    border: 0,
    color: 'white',
    padding: '0 20px',
    fontWeight: 'bold',
    fontSize: '1rem',
};
import React from 'react';
import {
    Button,
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { Confirmation } from '../models/confirmation.model';
import { formatToDollar } from '../../../common/helper.utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { buttonGradientStyle } from '../../../common/mui.style';
import styles from './confirmation.module.scss';

interface ConfirmationPageProps {
    confirmation: Confirmation;
}

const ConfirmationPage: React.FC<ConfirmationPageProps> = ({ confirmation }) => {
    const handlePrint = () => {
        window.print();
    };

    return (
        <div className={styles["confirmation-page"]}>
            <div>
                <div
                    style={{
                        background: 'linear-gradient(41deg, rgba(55,32,19,1) 7%, rgba(245,234,195,1) 50%, rgba(241,233,147,1) 80%)',
                        display: 'inline-block',
                        padding: '10px',
                        borderRadius: '50%',
                    }}
                >
                    <CheckCircleIcon
                        style={{
                            fontSize: '100px',
                            color: 'transparent',
                            'stroke': 'white',
                        }}
                    />
                </div>              <h1>Confirmation Number: </h1>
                                    <h2>{confirmation.confirmationCode}</h2>
                <p>
                    Please read the confirmation email and email all supporting documents and invoices to{' '}
                    <a href="mailto:Autotitledirect@gmail.com" style={{ color: 'white' }}>
                        Autotitledirect@gmail.com
                    </a>
                    .
                </p>
            </div>

            <div>
                <h2>Itemized Receipt</h2>
                <TableContainer style={{ maxWidth: '600px', margin: '0 auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ color: 'white' }}>
                                <TableCell style={{ color: 'white' }}>Service Type</TableCell>
                                <TableCell style={{ color: 'white' }}>VIN Number</TableCell>
                                <TableCell style={{ color: 'white' }}>Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {confirmation?.services.map((service) => (
                                <TableRow key={service.vinNumber} style={{ color: 'white' }}>
                                    <TableCell style={{color: 'white'}}>{service.serviceType}</TableCell>
                                    <TableCell style={{color: 'white'}}>{service.vinNumber}</TableCell>
                                    <TableCell style={{color: 'white'}}>{formatToDollar(service.price)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div>
                    <hr />
                    <h3>Total Balance: {formatToDollar(confirmation.totalPrice)}</h3>
                </div>
            </div>

            <div style={{ marginTop: '20px' , marginBottom: '50px'}}>
                <Button className={styles['action-button']} sx={buttonGradientStyle} variant="contained" color="primary" onClick={handlePrint}>
                    'Print Receipt'
                </Button>
            </div>
        </div>
    );
};

export default ConfirmationPage;

import React from 'react';
import { AppBar, Toolbar, Typography, Link } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import styles from './nav.module.scss'
import logo from '../../assets/img/atd-logo-nav.svg'
const useStyles = makeStyles((theme : any) => ({
  appBar: {
    background: theme.palette.background.default
  },
  link: {
    margin: theme.spacing(1, 2),
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
}));
const gradientStyle = {
  background: 'linear-gradient(31deg, rgba(12,14,21,1) 3%, rgba(28,33,47,1) 16%, rgba(55,54,59,1) 66%, rgba(59,58,62,1) 76%, rgba(93,90,89,1) 97%);'
  };
  
const Navigation: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={styles["nav"]}>
    <AppBar position="static" sx={gradientStyle} className={classes.appBar + " " + styles["sticky-nav"]}>
      <Toolbar>
        {/* <img className={styles['img-icon']} src='/img/logo-sm.PNG'/> */}
        <div className={styles["svg-container"]}>
        <img className={styles['svg-img-logo']} src={logo} alt="Logo" />

        </div>

        {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          AutoTitle
        </Typography> */}
        <NavLink to="/" className={classes.link}>
          Home
        </NavLink>
        <NavLink to="/services" className={classes.link}>
          Services
        </NavLink>
        <NavLink to="/contact" className={classes.link}>
          Contact
        </NavLink>
      </Toolbar>
    </AppBar>
    </div>

  );
};

export default Navigation;

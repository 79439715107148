import { loadStripe } from '@stripe/stripe-js';
import { app } from './firebaseConfig';

const projectId = app.options.projectId
export let stripePromise : any;

if(projectId == 'auto-title-direct-prod-82a69') {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_PROD || '');
} else {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_NONPROD || '');
}



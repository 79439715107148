import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import classes from './contact.module.scss';
import ContactMessage from './contactMessage/contactMessage.component';

const ContactPage: React.FC = () => {
    const handleEmailClick = () => {
        window.location.href = 'mailto:autotitledirect@gmail.com';
    };
    const handlePhoneNumberClick = () => {
        window.location.href = 'tel:+12194847019';
    };
    return (
        <div className={classes["contact-page"]}>
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                    >
                        <Typography color="secondary" variant="h1">
                            Contact
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="50%"
                        padding={'50px'}
                    >
                        <ContactMessage/>
                    </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Card
                        style={{
                            background: 'linear-gradient(31deg, rgba(12,14,21,1) 3%, rgba(28,33,47,1) 16%, rgba(55,54,59,1) 66%, rgba(59,58,62,1) 76%, rgba(93,90,89,1) 97%)'
                        }}
                        className={classes["form-card"]}
                    >
                        <CardContent className={classes["form-card-content"]}>
                            <div className={classes['content']}>
                                <LocalPhoneIcon sx={{ width: '2em', height: '2em' }} fontSize='large' color='secondary' />
                                <div className={classes['info']}>
                                    <Typography variant="h3" color="secondary">Phone Number</Typography>
                                    <Typography onClick={handlePhoneNumberClick} variant="h5" color="secondary">(915) 479-4626</Typography>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Card
                        style={{
                            background: 'linear-gradient(31deg, rgba(12,14,21,1) 3%, rgba(28,33,47,1) 16%, rgba(55,54,59,1) 66%, rgba(59,58,62,1) 76%, rgba(93,90,89,1) 97%)'
                        }}
                        className={classes["form-card"]}
                    >
                        <CardContent className={classes['form-card-content']}>
                            <EmailIcon sx={{ width: '2em', height: '2em' }} fontSize="large" color="secondary" />
                            <div className={classes['info']}>
                                <Typography variant="h3" color="secondary">
                                    Email
                                </Typography>
                                <Typography variant="h5" color="secondary" onClick={handleEmailClick} className={classes['email-link']}>
                                    autotitledirect@gmail.com
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default ContactPage;

import React, { ChangeEvent, useState } from 'react';
import {
    Card,
    CardContent,
    Button,
    Stepper,
    Step,
    StepLabel,
    SelectChangeEvent,
    Box,
    Typography,
    CircularProgress,
    Alert,
    Snackbar
} from '@mui/material';
import Company from './models/company.model';
import Service from './models/service.model';
import ServicesForm from './services-form/services-form.component';
import CompanyInfoForm from './company-info/company-info.component';
import CheckoutPage from './checkout/checkout.component';
import styles from './service-form.module.scss'
import { Container, Grid } from '@mui/material';
import { backButtonGradientStyle, buttonGradientStyle } from '../../common/mui.style';
import { Timestamp, getFirestore, collection, getDocs, addDoc, serverTimestamp, doc, query, setDoc, where, DocumentReference, DocumentData } from 'firebase/firestore';
import { app } from '../../firebaseConfig'
import { Confirmation } from './models/confirmation.model';
import Pricing from './models/pricing.model';
import ConfirmationPage from './confirmation/confirmation.component';
import CreditCardForm from '../../components/credit-card-form/credit-card-form.component';
import { CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { error } from 'console';
import { handleCompanyUpsert } from '../../common/db.helper';
import { stripePromise } from '../../stripeConfig';


const steps = ['Company Info', 'Services', 'Checkout'];

const initialCompanyState: Company = {
    companyName: '',
    address: '',
    phoneNumber: '',
    emailAddress: ''
};

const initialServiceState: Service = {
    serviceType: '',
    vinNumber: '',
    date: null,
    amount: 0,
    price: 0
};

const initialConfirmationState: Confirmation = {
    confirmationCode: '',
    emailSent: false,
    timestamp: Timestamp.now(),
    companyId: '',
    companyEmailAddress: '',
    totalPrice: 0,
    paymentId: '',
    services: [initialServiceState]
}

const initialPricingData: Pricing = {
    discountPrice: 0,
    basePrice: 0,
    discountThreshold: 0
}

const ServiceForm: React.FC = () => {
    const [errors, setErrors] = useState<Partial<any>>({});

    const [activeStep, setActiveStep] = useState(0);
    const [company, setCompany] = useState<Company>(initialCompanyState);
    const [isServicesFormValid, setIsServicesFormValid] = useState<boolean>(false);
    const [confirmation, setConfirmation] = useState<Confirmation>(initialConfirmationState);
    const [loading, setLoading] = useState(false); // New loading state
    const [pricingData, setPricingData] = useState<Pricing>(initialPricingData);
    const [isTransactionComplete, setIsTransactionComplete] = useState<boolean>(false);

    const validateVinNumber = (vinNumber: string): boolean => {
        // Modify the regular expression as per your requirements
        const regex = /^[A-HJ-NPR-Z0-9]{17}$/;
        return regex.test(vinNumber);
    };


    const getVinNumberError = (vinNumber: string): string => {
        if (!vinNumber) {
            return 'VIN Number is required';
        }
        if (!validateVinNumber(vinNumber)) {
            return 'Invalid VIN Number';
        }
        return '';
    };

    const getDateError = (date: Timestamp | null): string => {
        if (!date) {
            return 'Date is required';
        }

        // Add your date validation logic here
        // For example, check if the date is in the future or if it meets a specific format

        return '';
    };


    const validateServiceForm = () => {
        const updatedErrors: Partial<Service>[] = confirmation.services.map((service) => {
            const serviceErrors: Partial<Service> = {};

            // Validate VIN Number
            const vinNumberError = getVinNumberError(service.vinNumber);
            if (vinNumberError) {
                serviceErrors.vinNumber = vinNumberError;
            }

            const dateError = getDateError(service.date);
            if (dateError) {
                serviceErrors.date = null;
            }

            return serviceErrors;
        });

        const formErrors: Partial<Service> = updatedErrors.reduce(
            (prevErrors, currentErrors) => ({
                ...prevErrors,
                ...currentErrors
            }),
            {}
        );

        return confirmation.services.length !== 0 && Object.keys(formErrors).length === 0; // Return true if there are no errors
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCompanyInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = event.target;
        setCompany((prevCompany) => ({
            ...prevCompany,
            [name]: value,
        }));
    };

    const handleDeleteService = (index: number) => {
        const updatedServices = [...confirmation.services];
        updatedServices.splice(index, 1);
        setConfirmation((prevConfirmation) => ({
            ...prevConfirmation,
            services: updatedServices,
        }));
    };

    const handleServiceInputChange = (
        services: Service[], index: number) => {
        setConfirmation((prevConfirmation) => ({
            ...prevConfirmation,
            services: services,
        }));
    };

    const handleAddService = () => {
        setIsServicesFormValid(false);
        setConfirmation((prevConfirmation) => ({
            ...prevConfirmation,
            services: [...prevConfirmation.services, initialServiceState],
        }));
    };


    const handleServicesValidatiion = (isValid: boolean) => {
        setIsServicesFormValid(isValid);
    }

    const validateForm = () => {
        const { companyName, address, emailAddress, phoneNumber } = company;
        const errors: Partial<any> = {};

        if (!companyName) {
            errors.companyName = 'Company Name is required';
        }

        if (!address) {
            errors.address = 'Address is required';
        }

        // Validate emailAddress using regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(emailAddress)) {
            errors.emailAddress = 'Invalid Email Address';
        }

        // Validate phoneNumber using regex
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(phoneNumber)) {
            errors.phoneNumber = 'Invalid Phone Number';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0; // Return true if there are no errors
    };

    const onConfirmationChange = (confirm: Confirmation) => {
        setConfirmation(confirm);
    }

    const fetchData = async () => {
        const db = getFirestore(app);
        return await getDocs(collection(db, 'servicePricing')).then((pricingSnapshot) => {
            const pricing = pricingSnapshot.docs.map((doc) => doc.data() as Pricing)
            return pricing;
        });


    };

    const updateConfirmationWithPricing = (pricingData: Pricing) => {
        let totalPrice = 0;

        // Calculate pricing for services based on fetched data
        const updatedServices = confirmation.services.map((service) => {
            const { basePrice, discountPrice, discountThreshold } = pricingData;
            let price = 0;

            if (confirmation.services.length >= discountThreshold) {
                price = discountPrice;
            } else {
                price = basePrice;
            }
            service.price = price;
            totalPrice += price || 0;
            return { ...service, price };
        });
        setConfirmation((prevConfirmation) => ({
            ...prevConfirmation,
            totalPrice: totalPrice,
            services: updatedServices,
        }));
        setLoading(false);
    }


    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const isValid = validateForm();
        if (activeStep == 0 && isValid) {
            // Call the onFormSubmit function
            setLoading(true);
            handleCompanyUpsert(company).then((companyDoc) => {
                setConfirmation({...confirmation, companyId: companyDoc?.id || '', companyEmailAddress: company.emailAddress})
                handleNext();
                setLoading(false);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            })
        } else if (activeStep == 1 && validateServiceForm()) {
            setLoading(true); // Set loading to true before making the API call
            if (pricingData.basePrice == 0) {
                await fetchData().then((pricing: Pricing[]) => {
                    if (pricing.length > 0) {
                        setPricingData(pricing[0]);
                        updateConfirmationWithPricing(pricing[0]);
                    }
                    handleNext();
                })
            } else {
                updateConfirmationWithPricing(pricingData);
                handleNext();
            }

        }
    };



    return (
        <div className={styles["service-form"]}>
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Typography color="secondary" variant="h5">Service Catalog</Typography>
                    </Box>
                </Grid>

                <Grid className={styles["stepper-item"]} item sm={12} xs={12}>
                    <Stepper className={styles['stepper-container']} activeStep={activeStep}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                <Grid item sm={12} xs={12}>
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Card style={{
                            background: 'linear-gradient(31deg, rgba(12,14,21,1) 3%, rgba(28,33,47,1) 16%, rgba(55,54,59,1) 66%, rgba(59,58,62,1) 76%, rgba(93,90,89,1) 97%)'
                        }} className={styles["form-card"]}>
                            <CardContent className={styles["form-card-content"]}>

                                {!isTransactionComplete && activeStep === 0 && (
                                    <CompanyInfoForm company={company} onChange={handleCompanyInputChange} errors={errors} />
                                )}

                                {!isTransactionComplete && activeStep === 1 && (
                                    <ServicesForm
                                        services={[...confirmation.services]}
                                        onChange={handleServiceInputChange}
                                        onAddService={handleAddService}
                                        onDeleteService={handleDeleteService}
                                        validateServicesForm={handleServicesValidatiion} />
                                )}

                                {!isTransactionComplete && activeStep === 2 && (
                                    <div>
                                        <CheckoutPage
                                            company={company}
                                            confirmation={confirmation}
                                            pricingData={pricingData}
                                        />
                                        <div>
                                            <Elements stripe={stripePromise}>
                                                <CreditCardForm
                                                    company={company}
                                                    confirm={confirmation}
                                                    setOnLoadingStatus={setLoading}
                                                    setIsTransactionComplete={setIsTransactionComplete}
                                                    setConfirm={setConfirmation}
                                                />
                                            </Elements>
                                        </div>
                                    </div>
                                )}
                                {isTransactionComplete && <ConfirmationPage confirmation={confirmation} />}

                                {!isTransactionComplete && activeStep !== 3 && (
                                    <Grid className={styles['button-grid']} container spacing={2} >
                                        <Grid item xs={6}>
                                            {activeStep > 0 && (
                                                <Button sx={backButtonGradientStyle} className={styles["action-button"]} variant="contained" onClick={handleBack}>
                                                    Back
                                                </Button>
                                            )}
                                        </Grid>
                                        {activeStep != 2 && <Grid item xs={6}>
                                            <Button sx={buttonGradientStyle} className={styles["action-button"]} variant="contained" color="primary" onClick={handleFormSubmit}>
                                               {activeStep === 0 ? 'Next' : 'Checkout'}
                                            </Button>
                                        </Grid>}
                                    </Grid>)}



                            </CardContent>
                        </Card>)}
                </Grid>
            </Grid>


        </div>


    );
};

export default ServiceForm;
